<template>
  <personalidad-form
    btnSubmit="Crear Plantilla"
    :personalidad="data"
    @processForm="agregar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import personalidadForm from './PersonalidadForm.vue'

export default {
  components: {
    personalidadForm,
  },
  data() {
    return {
      data: {
        indicadores: [{
          id: 1,
          descripcion: '',
        }],
        id_cursos: [],
        descripcion: '',
        id_grupo_conceptos: null,
      },
    }
  },
  methods: {
    ...mapActions({ addPersonalidad: 'personalidades/addPersonalidad' }),
    agregar(personalidad) {
      // return false
      this.addPersonalidad(personalidad).then(() => {
        const errorPersonalidades = store.state.personalidades
        const errorMessage = errorPersonalidades.errorMessage.errors
        if (!errorPersonalidades.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Plantilla creada 👍',
              icon: 'CheckIcon',
              text: `La plantilla del informe de personalidad fue guardada con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 3000,
          })
          this.$router.replace({
            name: 'plantillas-personalidad',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Error en el ingreso de datos!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
